import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ReactAvatarEditor from 'react-avatar-editor';
import styled from '@emotion/styled';
import SliderComponent from '@mui/material/Slider';
import { forwardRef, useImperativeHandle, useRef, useState, } from 'react';
const SliderContainer = styled.div(props => ({
    display: 'flex',
    gap: `${props.theme.spacing_sizes.m}px`,
    alignItems: 'center',
}));
const Slider = styled(SliderComponent)(props => ({
    position: 'relative',
    padding: 0,
    background: `${props.theme.customColors.surface.outline}`,
    '& .MuiSlider-rail': {
        display: 'none',
    },
    '& .MuiSlider-thumb': {
        width: `${props.theme.spacing_sizes.xs * 2}px`,
        height: `${props.theme.spacing_sizes.xs * 2}px`,
        boxShadow: 'none',
        '&:before': {
            display: 'none',
        },
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxSizing: 'content-box',
            border: '3px solid #D8E0F8',
            boxShadow: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        '&:before': {
            display: 'none',
        },
        background: 'black',
        padding: `${props.theme.spacing_sizes.s / 2}px ${props.theme.spacing_sizes.s}px`,
        borderRadius: `${props.theme.spacing_sizes.s}px`,
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(0%, -70%)',
            '&:before': {
                display: 'none',
            },
        },
    },
}));
const EditorContainer = styled.div(props => ({
    marginBottom: `${Math.round(props.theme.spacing_sizes.s * 6.33)}px`,
}));
/*
original - 128px(уже обрезанная область)
avatar - 54px
thumbnail - 32px
*/
const Container = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    justifyContent: 'center',
    flex: 1,
}));
const ImageEditorComponent = ({ image, defaultImageZoom = 1, cropImageProps = {}, sliderStep = 0.1, minMaxImageZoom = [1, 3], }, outerRef) => {
    const editorRef = useRef(null);
    const [editorState, setEditorState] = useState(defaultImageZoom);
    const handleScale = (_, newValue) => {
        if (!Array.isArray(newValue)) {
            setEditorState(newValue);
        }
    };
    const getImages = () => {
        if (editorRef === null || editorRef === void 0 ? void 0 : editorRef.current) {
            // If you want the image resized to the canvas size (also a HTMLCanvasElement)
            const canvasScaled = editorRef.current.getImageScaledToCanvas();
            return canvasScaled;
        }
        return null;
    };
    useImperativeHandle(outerRef, () => ({
        getImages,
    }), []);
    return (_jsxs(Container, { children: [_jsx(EditorContainer, { children: _jsx(ReactAvatarEditor
                // eslint-disable-next-line react/jsx-props-no-spreading
                , Object.assign({}, cropImageProps, { 
                    // cursor fix for chrome
                    style: { '--webkit-cursor': 'grab' }, ref: editorRef, image: image, scale: 1 + editorState * (minMaxImageZoom[1] - minMaxImageZoom[0]) * 0.01 })) }), _jsx(SliderContainer, { children: _jsx(Slider, { size: 'medium', value: editorState, onChange: handleScale, step: sliderStep, valueLabelDisplay: 'off' }) })] }));
};
const ImageEditor = forwardRef(ImageEditorComponent);
export default ImageEditor;
