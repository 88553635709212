import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import CopyLink from '@shared/ui/misc/CopyLink';
import IconButton from '@shared/ui/buttons/Button/IconButton';
import { ReactComponent as CopyIcon } from '@icons/wolfkit-light/copy-square-light.svg';
import { ReactComponent as CopySuccessfulIcon } from '@icons/wolfkit-light/copy-square-check-light.svg';
const CopyLinkIconButton = ({ link, onCopySuccess = () => { }, hideTooltip = true, }) => {
    const [isCopySuccessful, setIsCopySuccessful] = useState(false);
    const handleCopySuccess = () => {
        setIsCopySuccessful(true);
        onCopySuccess();
    };
    return (_jsx(CopyLink, { link: link, onCopySuccess: handleCopySuccess, hideTooltip: hideTooltip, children: _jsx(IconButton, { variant: 'filled', size: 'medium', color: 'primary', type: 'button', IconComponent: isCopySuccessful ? CopySuccessfulIcon : CopyIcon }) }));
};
export default CopyLinkIconButton;
