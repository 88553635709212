import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { FormControl, MenuItem, } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { ReactComponent as CaretDownIcon } from '@icons/wolfkit-light/caret-down-light.svg';
import Icon from '@shared/ui/icons/Icon';
import IconText from '@shared/ui/icons/IconText';
import Select from '@shared/ui/inputs/Select';
import PaymentSystemsUtil from './PaymentSystemsUtil';
const Container = styled(FormControl)(() => ({
    display: 'flex',
    width: '100%',
}));
const IconTextStyled = styled(IconText)(props => ({
    color: `${props.theme.palette.text.primary}`,
}));
const SelectIcon = ({ className = undefined }) => (_jsx(Icon, { className: className, size: 20, IconComponent: CaretDownIcon }));
const CardSelect = ({ className = undefined, value = '', onChange = () => { }, disabled = undefined, label = undefined, }) => {
    const { t } = useTranslation();
    const paymentSystems = useMemo(() => PaymentSystemsUtil.getPaymentSystemsForSelect(t), [t]);
    const socialIcon = (type) => {
        if (paymentSystems[type]) {
            return paymentSystems[type].mainIcon;
        }
        return () => undefined;
    };
    const paymentName = (type) => { var _a; return ((_a = paymentSystems[type]) === null || _a === void 0 ? void 0 : _a.name); };
    const onSelectChange = (event) => {
        // NOTE: target.value should be SocialNetworkType,
        // but for some reason it is still a string in the end
        onChange(event.target.value);
    };
    const renderValue = (selected) => {
        if (!selected) {
            // TODO: change this point to autodetecting of payment system
            return 'Select payment system';
        }
        return (_jsx(IconTextStyled, { IconComponent: socialIcon(selected)(), text: paymentName(selected) }));
    };
    return (_jsx(Container, { className: className, children: _jsx(Select, { size: 'small', value: value, onChange: onSelectChange, displayEmpty: true, renderValue: renderValue, label: label, IconComponent: SelectIcon, disabled: disabled, children: Object.keys(paymentSystems).map((socialsKey, idx) => (_jsx(MenuItem, { value: socialsKey, children: _jsx(IconTextStyled, { IconComponent: socialIcon(socialsKey)(), text: paymentName(socialsKey) }) }, idx))) }) }));
};
export default CardSelect;
